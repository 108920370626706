import store from "@/state/store";

const userString = localStorage.getItem('user');
const userObject = userString ? JSON.parse(userString) : null;

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Авторизация",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "default" });
          next();
        } else {
          next();
        }
      },
    }
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Календарь поездок",
      authRequired: true,
    },
    component: () => import("../views/dashboard/dashboard.vue"),
  },
  {
    path: "/drivers",
    name: "drivers",
    meta: {
      title: "Управление водителями",
      authRequired: true,
    },
    component: () => import("../views/drivers/drivers.vue"),
    beforeEnter: (to, from, next) => {
      if (userObject && userObject.user_role !== 'driver') {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: "/driver/get/:driverId",
    name: "driversEdit",
    meta: {
      title: "Управление водителями",
      authRequired: true,
    },
    component: () => import("../views/drivers/driversEdit.vue"),
    beforeEnter: (to, from, next) => {
      console.log(userObject )
      if (userObject && userObject.user_role !== 'driver') {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: "/price/:driverId",
    name: "priceDriver",
    meta: {
      title: "Прайс лист водителя",
      authRequired: true,
    },
    component: () => import("../views/drivers/price.vue"),
    beforeEnter: (to, from, next) => {
      console.log(userObject )
      if (userObject && userObject.user_role !== 'driver') {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: "/add-manager/",
    name: "addManager",
    meta: {
      title: "Управление персоналом",
      authRequired: true,
    },
    component: () => import("../views/dashboard/manager.vue"),
    beforeEnter: (to, from, next) => {
      if (userObject && userObject.id === 1) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: "/task-manager/",
    name: "taskManager",
    meta: {
      title: "Список задач",
      authRequired: true,
    },
    component: () => import("../views/dashboard/manager/taskManager.vue"),
    beforeEnter: (to, from, next) => {
      if (userObject && userObject.user_role !== 'driver') {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: "/transport-management/",
    name: "transportManagement",
    meta: {
      title: "Управление транспортом",
      authRequired: true,
    },
    component: () => import("../views/dashboard/transportManagement.vue"),
    beforeEnter: (to, from, next) => {
      if (userObject && userObject.id === 1) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: "/driver-task/:guid",
    name: "driver-task",
    meta: {
      title: "Детальный просмотр",
      authRequired: true,
    },
    component: () => import("../views/drivers/Dynamic.vue"),
  },

  {
    path: "/success-task/",
    name: "success-task",
    meta: {
      title: "Завершенные поездки",
      authRequired: true,
    },
    component: () => import("../views/dashboard/driver/indexSuccess.vue"),
  },
  {
    path: "/file-manager/:path*",
    name: "files",
    meta: {
      title: "Файлы",
      authRequired: true,
    },
    component: () => import("../views/FileManager/FileManager.vue"),
    beforeEnter: (to, from, next) => {
      if (userObject && userObject.user_role !== 'driver') {
        next();
      } else {
        next('/');
      }
    },
    props: true
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Выход", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  },
  {
    path: '/:pathMatch(.*)*',
    meta: {
      title: "Ошибка 404",
      authRequired: true,
    },
    name: 'NotFound',
    component: () => import("../views/errors/404-cover")
  }
];